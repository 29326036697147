import { action, Action, Thunk, thunk } from 'easy-peasy';
import {
  SendVerificationTokenInput,
  CheckVerificationTokenInput,
  CheckVerificationTokenError,
  SendVerificationTokenResponse,
  VerifyHybridTokenInput,
} from '../gql/graphql';
import { Role } from '../helpers/cookies/cookie.helper';
import { ErrorMessage } from './applicationForm.store';

export enum launchPageTypes {
  checkout = 'checkout',
  financing = 'financing',
  product = 'product',
}

export interface AuthModel {
  sendVerificationToken: Thunk<AuthModel, SendVerificationTokenInput>;
  setSendVerificationTokenResponse: Action<AuthModel, SendVerificationTokenResponse>;
  sendVerificationTokenResponse?: SendVerificationTokenResponse;
  // General form error
  error?: Error | null;
  setErrorGeneral: Action<AuthModel, Error>;

  // Check Verification Token
  checkVerificationToken: Thunk<AuthModel, CheckVerificationTokenInput>;
  setWasCodeValidated: Action<AuthModel, boolean>;
  wasCodeValidated: boolean;

  checkTokenErrors: CheckVerificationTokenError[];
  setCheckTokenErrors: Action<AuthModel, CheckVerificationTokenError[]>;

  // Check the page modal was opened from
  setLaunchPage: Action<AuthModel, string | null>;
  launchPage: launchPageTypes;

  role: Role | null;
  setRole: Action<AuthModel, Role | null>;

  //Errors for when a query/mutation returns a 401 (unauthorized)
  unauthorizedError?: ErrorMessage | null;
  setUnauthorizedError: Action<AuthModel, ErrorMessage>;

  //Errors for when a query/mutation returns a 403 (forbidden)
  forbiddenError?: ErrorMessage | null;
  setForbiddenError: Action<AuthModel, ErrorMessage>;

  // Hybrid token
  verifyHybridToken: Thunk<AuthModel, VerifyHybridTokenInput>;
  hybridVerificationAttempted: boolean;
  setHybridVerificationAttempted: Action<AuthModel, boolean>;
  hybridVerificationSuccessful: boolean;
  setHybridVerificationSuccessful: Action<AuthModel, boolean>;
}

const auth: AuthModel = {
  /**
   * SEND VERIFICATION TOKEN
   */
  setSendVerificationTokenResponse: action((state, payload?) => {
    state.sendVerificationTokenResponse = payload;
  }),
  setErrorGeneral: action((state, payload?) => {
    state.error = payload;
  }),

  sendVerificationToken: thunk(async (actions, input: SendVerificationTokenInput, { injections }) => {
    const { authService } = injections;

    try {
      const response = await authService.sendVerificationToken(input);
      actions.setSendVerificationTokenResponse(response.data.sendVerificationToken);
    } catch (err: any) {
      actions.setErrorGeneral(err);
    }
  }),

  /**
   * CHECK VERIFICATION TOKEN
   */
  checkTokenErrors: [],
  wasCodeValidated: false,
  setWasCodeValidated: action((state, payload: boolean) => {
    state.wasCodeValidated = payload;
  }),
  setCheckTokenErrors: action((state, payload: CheckVerificationTokenError[]) => {
    state.checkTokenErrors = payload;
  }),
  checkVerificationToken: thunk(async (actions, input: CheckVerificationTokenInput, { injections }) => {
    const { authService } = injections;
    actions.setWasCodeValidated(false);
    try {
      const response = await authService.checkVerificationToken(input);

      if (response.data.checkVerificationToken.checkTokenErrors.length === 0) {
        actions.setWasCodeValidated(true);
      } else {
        actions.setCheckTokenErrors(response.data.checkVerificationToken.checkTokenErrors);
      }
    } catch (err: any) {
      // don't check for 401/403 here, as we don't want to block the user from trying again + checkVerificationToken is an open endpoint
      actions.setErrorGeneral(err);
    }
  }),
  launchPage: launchPageTypes.checkout,
  setLaunchPage: action((state, payload: string | null) => {
    switch (payload) {
      case 'product':
        state.launchPage = launchPageTypes.product;
        break;
      case 'financing':
        state.launchPage = launchPageTypes.financing;
        break;
      default:
        //default to highest perm page
        state.launchPage = launchPageTypes.checkout;
    }
  }),
  role: null,
  setRole: action((state, role: Role | null) => {
    state.role = role;
  }),
  /**
   * HANDLE UNAUTHORIZED (401)
   */
  unauthorizedError: null,
  setUnauthorizedError: action((state, payload: ErrorMessage) => {
    state.unauthorizedError = payload;
  }),

  /**
   * HANDLE FORBIDDEN (403)
   */
  forbiddenError: null,
  setForbiddenError: action((state, payload: ErrorMessage) => {
    state.forbiddenError = payload;
  }),
  /**
   * HYBRID TOKEN
   */
  hybridVerificationAttempted: false,
  setHybridVerificationAttempted: action((state, attempted: boolean) => {
    state.hybridVerificationAttempted = attempted;
  }),
  hybridVerificationSuccessful: false,
  setHybridVerificationSuccessful: action((state, successful: boolean) => {
    state.hybridVerificationSuccessful = successful;
  }),
  verifyHybridToken: thunk(async (actions, payload, { injections }) => {
    const { authService } = injections;
    try {
      await authService.verifyHybridToken(payload);
      actions.setHybridVerificationSuccessful(true);
    } catch (err: any) {
      // log error but don't throw/set it as if hybrid authentication fails, we don't want to block the user from continuing (bad UX),
      // they just need to re-authenticate through IDV (not great UX, but better than blocking)
      console.error(err);
    }
    // always set to true, as we don't want to block the user from continuing
    actions.setHybridVerificationAttempted(true);
  }),
};

export default auth;
