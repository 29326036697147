import { ApolloProvider } from '@apollo/client';
import '@koalafi/component-library/tailwind.min.css';
import { StoreProvider } from 'easy-peasy';
import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import client from './apollo';
import App from './App';
import CommonContainer from './components/common-container/common-container';
import CustomErrorBoundary from './components/customErrorBoundary/custom-error-boundary';
import './index.css';
import './segmentScript';
import './tailwind.css';
import { store } from './hooks';

//@ts-ignore
if (window.Cypress) {
  //@ts-ignore
  window.store = store;
}

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <ApolloProvider client={client}>
        {/* <Suspense fallback={<PageLoader data-testid="page-loader" />}> */}
        {/* Common container provides styling for the entire application - necessary to make sure any loaders/custom error boundaries live inside our app (modal or full screen) */}
        <CommonContainer>
          <ErrorBoundary FallbackComponent={(error) => <CustomErrorBoundary error={error} />}>
            <App />
          </ErrorBoundary>
        </CommonContainer>
        {/* </Suspense> */}
      </ApolloProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
