let env: string;
let edgeServiceUrl: string;
let segmentWriteKey: string;
let googleApiKey: string;
let sdkUrl: string;
let dealerPortalUrl: string;
let associatePortalUrl: string;

interface Config {
  env: string;
  edgeServiceUrl: string;
  segmentWriteKey: string;
  googleApiKey: string;
  sdkUrl: string;
  dealerPortalUrl: string;
  associatePortalUrl: string;
}

const getEnvConfig = (hostname = window?.location?.hostname, queryParams = new URLSearchParams(window.location.search)) => {
  if (hostname === 'epply.koalafi.com') {
    env = 'prod';
    edgeServiceUrl = 'https://application-edge-service.koalafi.com/query';
    segmentWriteKey = 'l1PTDCipwvubNqfFPO276xZ8yZt0cJj9';
    googleApiKey = 'AIzaSyCn262hdrH9l8JNqEi5pB-RJgNaZqkw6ek';
    sdkUrl = 'https://ecomm-sdk.koalafi.com';
    dealerPortalUrl = 'https://dealer.koalafi.com';
    associatePortalUrl = 'https://associate.koalafi.com';
  } else if (/dev/.test(hostname)) {
    env = 'dev';
    edgeServiceUrl = 'https://application-edge-service.dev.koalafi.com/query';
    segmentWriteKey = 'nAmtK8FJpulyIdntaj29f7XED49vFp8c';
    googleApiKey = 'AIzaSyCjLo1ArM2-gTheuEDJ32FfFkQk_GDvzHg';
    sdkUrl = 'https://ecomm-sdk.dev.koalafi.com';
    dealerPortalUrl = 'https://dealer.dev.koalafi.com';
    associatePortalUrl = 'https://associate.dev.koalafi.com';
  } else if (/stage/.test(hostname)) {
    env = 'stage';
    edgeServiceUrl = 'https://application-edge-service.stage.koalafi.com/query';
    segmentWriteKey = 'b6K1gsPxJS9ZghRdtc1H6CTT6e1FGPAJ';
    googleApiKey = 'AIzaSyCjLo1ArM2-gTheuEDJ32FfFkQk_GDvzHg';
    sdkUrl = 'https://ecomm-sdk.sandbox.koalafi.com';
    dealerPortalUrl = 'https://dealer.stage.koalafi.com';
    associatePortalUrl = 'https://associate.stage.koalafi.com';
  } else if (/sandbox/.test(hostname) && !queryParams.has('stage')) {
    env = 'sandbox';
    edgeServiceUrl = 'https://application-edge-service.sandbox.koalafi.com/query';
    segmentWriteKey = 'b6K1gsPxJS9ZghRdtc1H6CTT6e1FGPAJ';
    googleApiKey = 'AIzaSyCjLo1ArM2-gTheuEDJ32FfFkQk_GDvzHg';
    sdkUrl = 'https://ecomm-sdk.sandbox.koalafi.com';
    dealerPortalUrl = 'https://dealer.stage.koalafi.com';
    associatePortalUrl = 'https://associate.stage.koalafi.com';
  } else if (/sandbox/.test(hostname) && queryParams.has('stage')) {
    env = 'sandbox';
    edgeServiceUrl = 'https://application-edge-service.stage.koalafi.com/query';
    segmentWriteKey = 'b6K1gsPxJS9ZghRdtc1H6CTT6e1FGPAJ';
    googleApiKey = 'AIzaSyCjLo1ArM2-gTheuEDJ32FfFkQk_GDvzHg';
    sdkUrl = 'https://ecomm-sdk.sandbox.koalafi.com';
    dealerPortalUrl = 'https://dealer.stage.koalafi.com';
    associatePortalUrl = 'https://associate.stage.koalafi.com';
  } else if (process.env.REACT_APP_GRAPH_QL_ENDPOINT) {
    // e2e testing - adding dev segment tracking for now. Can remove later
    env = 'e2e';
    edgeServiceUrl = process.env.REACT_APP_GRAPH_QL_ENDPOINT;
    segmentWriteKey = '';
    googleApiKey = '';
    sdkUrl = '';
    dealerPortalUrl = '';
    associatePortalUrl = '';
  } else {
    env = 'local';
    edgeServiceUrl = 'http://localhost:8080/query';
    segmentWriteKey = '';
    googleApiKey = 'AIzaSyCjLo1ArM2-gTheuEDJ32FfFkQk_GDvzHg';
    sdkUrl = 'http://localhost:3001';
    dealerPortalUrl = 'https://dealer.dev.koalafi.com';
    associatePortalUrl = 'https://associate.dev.koalafi.com';
  }

  return { env, edgeServiceUrl, segmentWriteKey, googleApiKey, sdkUrl, dealerPortalUrl, associatePortalUrl };
};

let AppConfig: Config = getEnvConfig();

export { AppConfig, getEnvConfig };
