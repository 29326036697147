import { store } from '../../hooks';
import { useStoreState } from '../../hooks/easyPeasyHooks';
import { conditions } from './neuroId.helper';

// used in a react context
export const useStoreConditions = (): conditions => {
  return {
    isVirtualCard: useStoreState((state) => state.order.order?.dealer.isVirtualCard),
    channelType: useStoreState((state) => state.order?.order?.dealer?.channelType),
    highestSupportedApplicationMilestone: useStoreState((state) => state.featureFlags.highestSupportedApplicationMilestone),
    role: useStoreState((state) => state.auth.role),
    neuroid_enabled: useStoreState((state) => state.featureFlags?.featureFlags?.neuroid_enabled),
  };
};

// used in non react contexts
export const getConditions = (): conditions => {
  return {
    isVirtualCard: store.getState().order.order?.dealer.isVirtualCard,
    channelType: store.getState().order?.order?.dealer?.channelType,
    highestSupportedApplicationMilestone: store.getState().featureFlags.highestSupportedApplicationMilestone,
    role: store.getState().auth.role,
    neuroid_enabled: store.getState().featureFlags.featureFlags?.neuroid_enabled,
  };
};
