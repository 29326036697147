import { ChannelTypes } from '../../gql/graphql';
import { Role } from '../cookies/cookie.helper';

export type conditions = {
  isVirtualCard?: boolean;
  channelType?: ChannelTypes;
  highestSupportedApplicationMilestone?: number | null;
  role?: Role | null;
  neuroid_enabled?: boolean;
};

export const shouldProcessNeuroId = (params: conditions): boolean => {
  const isBrickAndMortar = params.channelType === ChannelTypes.BrickAndMortar;
  const isCustomerRole = params.role === Role.Customer;
  const isMilestoneAppropriate = !!params.highestSupportedApplicationMilestone && params.highestSupportedApplicationMilestone > 1;
  const isChannelTypeAppropriate = params.isVirtualCard || isBrickAndMortar;
  const isNeuroIdEnabled = !!params.neuroid_enabled;
  return isCustomerRole && isMilestoneAppropriate && isChannelTypeAppropriate && isNeuroIdEnabled;
};

export const initializeNeuroIdSession = (isLLE: boolean) => {
  const script = document.createElement('script');

  const testENV = isLLE ? '-test' : '';

  script.innerHTML = `
    (function(n,e,u,r,o,i,d){
        n.nid=n.nid||function(){(n.nid.q=n.nid.q||[]).push(arguments)};
        o=e.createElement(u);i=e.getElementsByTagName(u)[0];o.async=1;
        o.src=r;i.parentNode.insertBefore(o,i);
    })(window,document,'script','//scripts.neuro-id.com/c/nid-atoms475${testENV}.js');
  `;
  document.head.appendChild(script);

  nid('start', { linkedSiteId: 'form_anvil047' });
};
