import { getConditions } from '../neuroId/neuroId.conditions.helper';
import { shouldProcessNeuroId } from '../neuroId/neuroId.helper';
import { getUnifiedToken, transferToken } from './cookie.helper';

export enum UiType {
  Loans = 'Loans',
  Unified = 'Unified',
  Rapid = 'Rapid',
}

const loansUrlRegex = /apply.+koalafi\.com/;
const unifiedUrlRegex = /epply.+koalafi\.com/;
const rapidUrlRegex = /dealer.+koalafi\.com/;

// bandaid function until we can access the machine context from the pages
// the main issue is the the token is generated and updated at variable times,
// so using this function to check at the latest possible time is the most reliable option
export const shouldTransferToLoans = (unifiedToken?: transferToken | null): boolean => {
  if (!unifiedToken) unifiedToken = getUnifiedToken();
  return !!unifiedToken?.targetUi && loansUrlRegex.test(unifiedToken?.targetUi);
};

// for phase 1, rapid redirects will go to loans, which will waterfall to rapid.
// This is until the rapid landing code can be built out
export const shouldTransferToRapid = (unifiedToken?: transferToken | null): boolean => {
  if (!unifiedToken) unifiedToken = getUnifiedToken();
  return !!unifiedToken?.targetUi && rapidUrlRegex.test(unifiedToken?.targetUi);
};

// should transfer to either rapid or loans
export const shouldTransfer = (unifiedToken?: transferToken | null): boolean => {
  if (!unifiedToken) unifiedToken = getUnifiedToken();
  return !!unifiedToken?.targetUi && (rapidUrlRegex.test(unifiedToken?.targetUi) || loansUrlRegex.test(unifiedToken?.targetUi));
};

export const transferToLoansUi = () => {
  const unifiedToken = getUnifiedToken(); // this call is duplicated here to get the most up to date token
  var transferUrl = `${unifiedToken?.targetUi}/?dealerId=${unifiedToken!.dealerPublicId}&unifiedToken=${unifiedToken!.token}`;

  // propagate campaign medium. Campaign source is not used by loans
  const cm = new URLSearchParams(document.location.search.substring(1)).get('cm');
  if (cm) transferUrl += `&cm=${cm}`;

  if (shouldTransferToLoans(unifiedToken)) {
    window.location.href = transferUrl;
  }
};

// for phase 1, rapid redirects will go to loans, which will waterfall to rapid.
// This is until the rapid landing code can be built out
export const transferToRapid = () => {
  const unifiedToken = getUnifiedToken(); // this call is duplicated here to get the most up to date token
  var transferUrl = `${unifiedToken?.targetUi}/ApplicationForm/UnifiedApplication?publicStoreId=${unifiedToken!.dealerPublicId}&unifiedToken=${
    unifiedToken!.token
  }`;

  if (shouldTransferToRapid(unifiedToken)) {
    if (shouldProcessNeuroId(getConditions())) nid('applicationSubmit');

    // propagate campaign medium and campaign source
    const cm = new URLSearchParams(document.location.search.substring(1)).get('cm');
    if (cm) transferUrl += `&cm=${cm}`;
    const cs = new URLSearchParams(document.location.search.substring(1)).get('cs');
    if (cs) transferUrl += `&cs=${cs}`;

    window.location.href = transferUrl;
  }
};

export const getTargetUi = (): UiType => {
  const unifiedToken = getUnifiedToken();
  if (!unifiedToken) return UiType.Unified;
  switch (true) {
    case loansUrlRegex.test(unifiedToken?.targetUi):
      return UiType.Loans;
    case unifiedUrlRegex.test(unifiedToken?.targetUi):
      return UiType.Unified;
    case rapidUrlRegex.test(unifiedToken?.targetUi):
      return UiType.Rapid;
  }
  return UiType.Unified;
};
